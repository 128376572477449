<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <v-row dense v-if="$route.path !== '/dashboard'" class="mx-0 px-0" style="border-top: #e1dce8 solid 1px; color: lightgray;border-bottom: #e1dce8 solid 1px;">
    <v-col cols="2" class="mx-0">
      <v-text-field
          class="py-1 ml-5"
          prepend-inner-icon="mdi-magnify"
          hide-details
          dense
          clearable
          v-if="$route.path === '/medicalstaff' || $route.path === '/patients' || $route.path === '/wellnessassessments' || $route.path === '/billings' || $route.path === '/mentalhealthpatient'"
          v-model="search"
          :label="$t('search')"
          outlined
      ></v-text-field>
      <v-text-field
          cols="2"
          class="py-1 ml-5"
          prepend-inner-icon="mdi-magnify"
          hide-details
          dense
          v-if="$route.path === '/medicalteam'"
          v-model="medical"
          :label="$t('search')"
          outlined
      ></v-text-field>
    </v-col>
    <v-col cols="2" class="ma-0 px-1">
      <v-select
          class="py-1"
          hide-details
          dense
          v-if="$route.path === '/medicalstaff'"
          v-model="role"
          :items="roles"
          item-value="id"
          :item-text="$i18n.locale === 'en' ? 'roleNameForDisplay' : 'roleNameForDisplayESP'"
      :label="$t('role')"
          outlined
      >
      </v-select>

    </v-col>
    <v-col cols="2" class="ma-0 px-1">
      <v-select
          class="py-1"
          hide-details
          dense
          v-model="surgery"
          v-if="$route.path === '/medicalstaff'"
          :item-text="$i18n.locale === 'en' ? 'longDescription' : 'longDescriptionEsp'"
          item-value="lowValue"
          :items="getSpecializations"
          :label="$t('specialization')"
          outlined
      ></v-select>
    </v-col>
<!--    <v-col cols="2" class="ma-0 px-1">-->
<!--      <v-switch-->
<!--          class="d-flex justify-end py-1 float-right"-->
<!--          hide-details-->
<!--          dense-->
<!--          v-model="switch1"-->
<!--          v-if="$route.path === '/medicalstaff'"-->
<!--          outlined-->
<!--      ></v-switch>-->
<!--    </v-col>-->
    <div style="display:flex; align-items:center; " v-if="$route.path === '/billings'" class="ml-auto mr-2">
      <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="-90"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="selectedDateForBilling"
                label="Filter date"
                readonly
                v-bind="attrs"
                single-line
                hide-details
                prepend-inner-icon="mdi-calendar"
                dense
                v-on="on"
                class="mr-5"
                outlined
            ></v-text-field>
        </template>
        <v-date-picker v-model="selectedDateForBilling" color="primary" type="month" no-title scrollable @change="menu1 = false"></v-date-picker>
      </v-menu>
      <div v-if="$route.path === '/billings' && this.roleName === 'HOSPITAL_ADMIN'">
        <v-btn @click="generateBillingDataForHospitalAdmin" class="chips" style="">
          <img class="my-1" height="17px" width="20px" :src="invoice"/>
        </v-btn>
      </div>
      <div v-else-if="$route.path === '/billings' && this.roleName === 'ORGANIZATION_ADMIN'">
        <v-btn @click="generateBillingDataForOrganizationalAdmin" class="primary" style="margin-top:13px; margin-right:20px;">
          <img class="my-1" height="17px" width="20px" :src="invoice"/>
        </v-btn>
      </div>
	  	<v-btn @click="generateCSV" color="chips" variant="tonal" class="ml-2" style="text-transform: capitalize; color:white;"  prepend-icon="mdi-plus">
				<span class="mdi mdi-cloud-download mdi-18px mt-1"></span>
		</v-btn>
    </div>

    <v-col cols="6" class="d-flex align-center justify-end py-1">
      <v-switch
          class="d-flex justify-end py-1 pr-2 float-right"
          hide-details
          dense
          v-model="usersActivity"
          v-if="$route.path === '/patients'"
          outlined
      ></v-switch>
      <v-btn v-if="$route.path !=='/billings'" @click="syncronizeDrawers" class="add-button-style">
        <img class="me-3" height="15" :src="addWhite"/>
        <span style="text-transform: capitalize;">{{$t(pathname) }}</span>
      </v-btn>
    </v-col>

  </v-row>
  <v-row v-else-if="$route.path === '/dashboard'" class="mx-2 px-2" style="border-top: #e1dce8 solid 1px; background-color: #f2f6fe">
    <v-col cols="12">
      <!--    <v-alert-->
      <!--        height="40"-->
      <!--        dense-->
      <!--        type="error"-->
      <!--        color="#D90808"-->
      <!--				class="mb-0"-->
      <!--    >-->
      <!--      <v-row>-->
      <!--        <v-col class="text-left" align-self="center">-->
      <!--          Patient John Doe is having SI thoughts!-->
      <!--        </v-col>-->
      <!--        <v-col class="shrink">-->
      <!--          <v-btn :height="33" color="white" style="background-color: white; color: black; margin-top:-4.5px; margin-right:-12px;">{{$t('resolve')}}</v-btn>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!--    </v-alert>-->
    </v-col>
  </v-row>
</template>

<script>
import {
	addWhite,
	billWhite,
} from '@/assets';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
	name: 'DashboardCoreDrawer',
	components: {},
	props: {
		expandOnHover: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		usersActivity: true,
		addWhite: addWhite,
		items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
		search: null,
		role: 0,
		invoice: billWhite,
		selectedDateForBilling: new Date().toISOString().substr(0, 7),
		medical: null,
		menu1: false,
		surgery: -1,
		selectedMedicalInstitution: -1,
	}),
	// async mounted () {
	// },
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
		}),
		...mapGetters({
			roles: 'roles/getRoles',
			getSpecializations: 'verifiers/getSpecializations',
			medicalInstitutions: 'medicalInstitutions/getMedicalInstitutionsSorted',
			getMedicalTeam: 'filterbar/getMedicalTeam',
			roleName: 'authentication/getRole',
		}),
		pathname () {
			return this.$route.name;
		},
		drawer: {
			get () {
				return this.$store.state.drawer;
			},
			set (val) {
				this.$store.commit('SET_DETAILS_DRAWER', val);
			},
		},
		detailsDrawer () {
			return this.$store.state.detailsDrawer;
		},
	},
	methods: {
		...mapMutations({
			setDrawer: 'SET_DETAILS_DRAWER',
		}),
		syncronizeDrawers () {
			var parameters = {};
			switch (this.pathname.toLowerCase()) {
				case 'medical staff':
					parameters = {
						detailsDrawer: true,
						page: this.pathname.toLowerCase(),
					};
					this.$store.commit('SET_DETAILS_DRAWER', parameters);
					break;
				case 'patients':
					parameters = {
						detailsDrawer: true,
						page: this.pathname.toLowerCase(),
					};
					this.$store.commit('SET_DETAILS_DRAWER', parameters);
					break;
				case 'medical team':
					parameters = {
						detailsDrawer: true,
						page: this.pathname.toLowerCase(),
					};
					this.$store.commit('SET_DETAILS_DRAWER', parameters);
					break;
				case 'assessments':
					parameters = {
						detailsDrawer: true,
						page: this.pathname.toLowerCase(),
					};
					this.$store.commit('SET_DETAILS_DRAWER', parameters);
					break;
				case 'verifier':
					parameters = {
						detailsDrawer: true,
						page: this.pathname.toLowerCase(),
					};
					this.$store.commit('SET_DETAILS_DRAWER', parameters);
					break;
				case 'organization':
					parameters = {
						detailsDrawer: true,
						page: this.pathname.toLowerCase(),
					};
					this.$store.commit('SET_DETAILS_DRAWER', parameters);
					break;
				case 'office':
					parameters = {
						detailsDrawer: true,
						page: this.pathname.toLowerCase(),
					};
					this.$store.commit('SET_DETAILS_DRAWER', parameters);
					break;
			}
		},
		async generateBillingDataForHospitalAdmin () {
			const data = { hospitalId: this.userData.hospitalAssignedData.id };
			await this.$store.dispatch('filterbar/billingDataForHospitalAdmin', data);
		},
		async generateBillingDataForOrganizationalAdmin () {
			const data = { organizationId: this.userData.organizationId };
			await this.$store.dispatch('filterbar/billingDataForOrganizationalAdmin', data);
		},
		async generateCSV () {
			const data = { organizationId: this.userData.organizationId };
			await this.$store.dispatch('filterbar/generateCSV', data);
		},
	},
	watch: {
    '$route' (to, from) {
      if (to.path !== '/dashboard') {
        this.search = null;
      }
    },
		async usersActivity (val) {
			await this.$store.dispatch('filterbar/usersActivity', val);
		},
		async surgery (val) {
			await this.$store.dispatch('filterbar/AppBarSpacialization', val);
		},
		async role (val) {
			await this.$store.dispatch('filterbar/AppBarRole', val);
		},
		async search (val) {
			await this.$store.dispatch('filterbar/AppBarSearch', val);
		},
		async medical (val) {
			await this.$store.dispatch('filterbar/AppBarMedicalTeamDoctor', val);
		},
		async selectedMedicalInstitution (val) {
			await this.$store.dispatch('filterbar/AppBarMedicalInstitution', val);
		},
		async selectedDateForBilling (val) {
			await this.$store.dispatch('filterbar/AppBarDate', val);
		},
	},
};
</script>

<style>
.custom-alert {
  height: 50px; /* Adjust the height as desired */
}

.custom-alert-text {
  height: 50%; /* Adjust the height as desired */
  font-size: 50%; /* Optionally adjust the font size as desired */
}

.custom-alert-btn {
  height: 50%; /* Adjust the height as desired */
  line-height: 50%; /* Optionally adjust the line height as desired */
  font-size: 50%; /* Optionally adjust the font size as desired */
}
.border-top {
  border-top: 1px solid black;
  border-color: gray;
}
.border {
  border: 1px solid black;
}
</style>
