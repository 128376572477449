<template>
  <v-dialog
      v-model="dialog"
      width="720"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      class="dialog-style"
  >
    <v-card >
      <v-card-title class="secondary py-1" style="height: 40px">
<!--        <span class="dialog-title">{{$t('profile')}}</span>-->
          <span class="dialog-title" style="font-size: 18px">{{$t('personal')}} {{$t('information')}}</span>
        <v-spacer />
        <v-icon small color="primary" @click="$emit('false')"
        >mdi-window-close</v-icon
        >
      </v-card-title>
      <v-card-text >
        <v-row>
<!--          <v-col>-->
<!--            <span style="font-size: 18px">{{$t('personal')}} {{$t('information')}}</span>-->
<!--          </v-col>-->
          <span class="my-5 mx-2">
            <v-btn-toggle
                v-model="language"
                row
                mandatory
                active-class="primary white--text"
                rounded
            >
              <v-btn
                  label="ENG"
                  value='en'
                  style="height: 25px;"
              >{{ $t("en") }}</v-btn>
              <v-btn
                  label="ESP"
                  value='es'
                  style="height: 25px;"
              >{{ $t("es") }}</v-btn>
            </v-btn-toggle>
          </span>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
                v-model="userDetails.firstName"
                :label="$t('name')"
                prepend-inner-icon="mdi-account mr-1"
                dense
                background-color="#E8E8E8"
                rounded
                outlined
                style="border-radius: 12px !important; height: 40px; width: 330px"
                :rules="[(rule) => !!rule || '']"
            ></v-text-field>
          </v-col>
          <v-col >
            <v-text-field
                v-model="userDetails.lastName"
                :label="$t('surname')"
                prepend-inner-icon="mdi-account mr-1"
                dense
                background-color="#E8E8E8"
                rounded
                outlined
                style="border-radius: 12px !important; height: 40px; width: 330px"
                :rules="[(rule) => !!rule || '']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-text-field
                v-model="userDetails.email"
                :label="$t('email')"
                prepend-inner-icon="mdi-email mr-1"
                dense
                background-color="#E8E8E8"
                rounded
                outlined
                style="border-radius: 12px !important; height: 40px; width: 330px"
                :rules="[(rule) => !!rule || '']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-text-field
                ref="phoneNumber"
                v-model="userDetails.phone"
                validate-on-blur
                :label="$t('phone-number')"
                prepend-inner-icon="mdi-cellphone mr-4"
                dense
                background-color="#E8E8E8"
                rounded
                outlined
                style="border-radius: 12px !important; height: 40px; width: 330px"
                :rules="[(rule) => !!rule || '']"
            ></v-text-field>
          </v-col>
        </v-row>
<!--        <hr class="my-3"/>-->
        <v-row class="mt-6 ml-2 ">
          <span style="font-size: 18px">{{$t('units')}}</span>
        </v-row>
        <v-row class="mt-1">
          <v-col>
            <span class="primary--text d-flex justify-start mx-3">{{$t('weight')}} & {{$t('temperature')}}</span>
            <span class="d-flex justify-start">
             <v-btn-toggle
                 v-model="scaleAndTempUnit"
                 row
                 mandatory
                 active-class="primary white--text"
                 rounded
             >
              <v-btn
                  @click="setImperialOrMetric('IMPERIAL')"
                  style="width: 80px; font-size: 13px; justify-content: center; height: 30px"
                  value='IMPERIAL'
              >imperial</v-btn>
              <v-btn
                  value='METRIC'
                  @click="setImperialOrMetric('METRIC')"
                  style="width: 80px; font-size: 13px; justify-content: center; height: 30px"
              >metric</v-btn>
            </v-btn-toggle>
          </span>
<!--            <span class="d-flex justify-start">-->
<!--            <v-chip-group-->
<!--                active-class="primary white&#45;&#45;text"-->
<!--                column-->
<!--                mandatory-->
<!--                v-model="glucoseAndCholesterolUnit"-->
<!--            >-->
<!--              <v-chip-->
<!--                  value="mg/dl"-->
<!--                  @click="setGlucoseAndCholesterolUnit('mg/dl')"-->
<!--                  style="width: 80px; font-size: 13px; justify-content: center"-->
<!--              >mg/dL</v-chip-->
<!--              >-->
<!--              <v-chip-->
<!--                  value="mmol/l"-->
<!--                  @click="setGlucoseAndCholesterolUnit('mmol/l')"-->
<!--                  style="width: 80px; font-size: 13px; justify-content: center"-->
<!--              >mmol/L</v-chip-->
<!--              >-->
<!--            </v-chip-group>-->
<!--              </span>-->
          </v-col>
          <v-col>
            <span class="primary--text d-flex justify-end mx-7">{{$t('glucose')}} & {{$t('cholesterol')}}</span>
            <span class="d-flex justify-end mx-4">
               <v-btn-toggle
                   v-model="glucoseAndCholesterolUnit"
                   row
                   mandatory
                   active-class="primary white--text"
                   rounded
               >
              <v-btn
                  @click="setGlucoseAndCholesterolUnit('mg/dl')"
                  value='mg/dl'
                  style="width: 80px; font-size: 13px; justify-content: center; height: 30px"
              >mg/dL</v-btn>
              <v-btn
                  value='mmol/l'
                  @click="setGlucoseAndCholesterolUnit('mmol/l')"
                  style="width: 80px; font-size: 13px; justify-content: center; height: 30px"
              >mmol/L</v-btn>
            </v-btn-toggle>
          </span>
<!--            <span class="d-flex justify-end">-->
<!--            <v-chip-group-->
<!--                active-class="primary white&#45;&#45;text"-->
<!--                column-->
<!--                mandatory-->
<!--                v-model="scaleAndTempUnit"-->
<!--            >-->
<!--              <v-chip-->
<!--                  class="text-center"-->
<!--                  value="IMPERIAL"-->
<!--                  @click="setImperialOrMetric('IMPERIAL')"-->
<!--                  style="width: 80px; font-size: 13px; justify-content: center"-->
<!--              >{{$t('imperial')}}</v-chip-->
<!--              >-->
<!--              <v-chip-->
<!--                  value="METRIC"-->
<!--                  @click="setImperialOrMetric('METRIC')"-->
<!--                  style="width: 80px; font-size: 13px; justify-content: center"-->
<!--              >{{$t('metric')}}</v-chip-->
<!--              >-->
<!--            </v-chip-group>-->
<!--              </span>-->
          </v-col>
        </v-row>
          <v-row
              dense
              style="cursor: pointer"
              @click="changePasswordSection = !changePasswordSection"
          >
          </v-row>
        <v-row v-if="changePasswordSection" dense class="mx-1">
          <v-col>
            <v-form v-model="valid">
              <v-row dense>
                <v-text-field
                    type="password"
                    :rules="currentPasswordRule"
                    v-model="currentPassword"
                    :label="$t('current-password')"
                    prepend-inner-icon="mdi-lock-check mr-1"
                    outlined
                    dense
                    filled
                    style="border-radius: 12px"
                ></v-text-field>
              </v-row>
              <v-row dense >
                <v-text-field
                    :rules="newPasswordRule"
                    v-model="newPassword"
                    type="password"
                    :label="$t('new-password')"
                    prepend-inner-icon="mdi-lock-plus mr-1"
                    outlined
                    dense
                    filled
                    style="border-radius: 12px"
                >
                </v-text-field>
              </v-row>
              <v-row dense>
                <v-text-field
                    :rules="confirmNewPasswordRule"
                    v-model="confirmNewPassword"
                    type="password"
                    :label="$t('confirm-new-password')"
                    prepend-inner-icon="mdi-lock-reset mr-1"
                    outlined
                    dense
                    filled
                    style="border-radius: 12px"
                >
                </v-text-field>
              </v-row>
              <v-row>
              </v-row>
              <v-card-actions>
                <v-btn
                    class="table-create-button elevation-0"
                    :disabled="!valid"
                    @click="changePassword"
                    style="border-radius: 12px"
                >
                  <!-- <v-icon small class="icon">mdi-pencil-plus-outline</v-icon> -->
                  <span>{{$t('change')}}</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
        </v-row>
        <v-row class="mr-1">
          <v-col>
            <v-btn style="float: left; border-radius: 12px;" class="change-password-button"  @click="changePasswordSection = !changePasswordSection">
              <span style="font-size: 15px;">{{$t('change')}} {{$t('password')}}</span>
            </v-btn></v-col>
          <v-col>
            <v-btn style="float: right;" class="table-cancel-button" @click="$emit('false')">
              <span color="black--text">{{ $t("cancel") }}</span>
            </v-btn>
            <v-btn style="float: right;" class="table-button mx-2" :loading="loading" :disabled="loading" @click="loader = 'loading'">
              <span>{{ $t("save") }}</span>
            </v-btn></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
// import BirthdatePicker from '@/views/Client/components/BirthdatePicker';
import accountOutline from '@/assets/icons_v2/account-outline.png';
import { AsYouType } from 'libphonenumber-js';

export default {
	props: ['dialog', 'specializations'],
	components: {
		// BirthdatePicker,
	},
	data () {
		return {
			loader: null,
			loading: false,
			currentPassword: '',
			newPassword: '',
			confirmNewPassword: '',
			valid: false,
			changePasswordSection: false,
			profile: accountOutline,
			firstName: null,
			lastName: null,
			birthdate: null,
			phoneNumber: null,
			address: null,
			email: null,
			gender: null,
			scaleAndTempUnit: '',
			glucoseAndCholesterolUnit: '',
			address_info: {
				address: '',
				postal_code: '',
				city: '',
				state: '',
				country: '',
				lat: 42.001071,
				lng: 20.957554,
			},
			formattedAddress: null,
			timezone: null,
			language: '',
			title: null,
			snackStatus: null,
			snackColor: null,
		};
	},
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
		}),
		...mapGetters({
			userSettings: 'authentication/getUserSettings',
		}),
		currentPasswordRule () {
			if (this.currentPassword === '') {
				return ['Field should not be empty!'];
			} else if (this.currentPassword.length < 7) {
				return ['Password should have more than 6 characters!'];
			} else {
				return [];
			}
		},
		newPasswordRule () {
			if (this.newPassword === '') {
				return ['Field should not be empty!'];
			} else if (this.newPassword.length < 7) {
				return ['Password should have more than 6 characters!'];
			} else {
				return [];
			}
		},
		confirmNewPasswordRule () {
			if (this.confirmNewPassword === '') {
				return ['Field should not be empty!'];
			} else if (this.confirmNewPassword !== this.newPassword) {
				return ['Passwords do not match', true];
			} else {
				return [];
			}
		},
	},
	watch: {
		language (val) {
			var tempcookie;
			switch (val) {
			case 'en':
				tempcookie = 'ENG';
				this.$cookies.set('language', 'en', '6m');
				break;
			case 'es':
				tempcookie = 'ESP';
				this.$cookies.set('language', 'es', '6m');
				break;
			}
			this.$i18n.locale = this.$cookies.get('language');
			var body = {
				Language: tempcookie,
			};
			this.$store.dispatch('users/changemyLanguage', body);
		},
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.update()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		'userDetails.phone': function (val) {
			if (val !== undefined) {
				this.userDetails.phone = new AsYouType().input(val);
			}
		},
	},
	mounted () {
		this.scaleAndTempUnit = this.userSettings.Units;
		this.glucoseAndCholesterolUnit = this.userSettings.Concentration;
		this.addressData();
		this.language = this.$cookies.get('language');
	},
	methods: {
		async addressData () {
			this.address_info = {
				address: this.userDetails.address,
				postal_code: this.userDetails.zip,
				city: this.userDetails.city,
				state: this.userDetails.state,
				country: this.userDetails.country,
				lat: this.userDetails.addressLatitude,
				lng: this.userDetails.addressLongitude,
			};
			this.timezone = this.userDetails.userTimezone;
		},
		// async locationDetails () {
		// 	if (this.userDetails.address) {
		// 		const geoLocation = await this.$store.dispatch(
		// 			'googleAPI/getGeoLocation',
		// 			this.userDetails.address,
		// 		);
		// 		const addressComponents = geoLocation.address_components;
		// 		this.address_info.lat = geoLocation.geometry.location.lat;
		// 		this.address_info.lng = geoLocation.geometry.location.lng;
		// 		this.formattedAddress = geoLocation.formatted_address;
		// 		this.address_info.address = this.formattedAddress;
		// 		addressComponents.forEach((ac) => {
		// 			if (ac.types.includes('administrative_area_level_1')) {
		// 				this.address_info.state = ac.long_name;
		// 			}
		// 			if (ac.types.includes('locality')) {
		// 				this.address_info.city = ac.long_name;
		// 			}
		// 			if (ac.types.includes('country')) {
		// 				this.address_info.country = ac.long_name;
		// 			}
		// 			if (ac.types.includes('postal_code')) {
		// 				this.address_info.postal_code = ac.long_name;
		// 			}
		// 		});
		// 		if (this.address_info.lat !== null) {
		// 			const tz = await this.timeZone(
		// 				this.address_info.lat,
		// 				this.address_info.lng,
		// 			);
		// 			if (tz) {
		// 				this.timezone = tz.zoneName;
		// 			}
		// 		}
		// 	}
		// },
		async locationDetails () {
			if (this.userDetails.address) {
				this.waiting = true;
				const geoLocation = await this.$store.dispatch(
					'googleAPI/getGeoLocation',
					this.userDetails.address,
				);
				this.waiting = false;
				if (!geoLocation) {
					this.validationErrors.push('address');
					this.address_info = {
						address: '',
						postal_code: '',
						city: '',
						state: '',
						country: '',
						lat: 0,
						lng: 0,
					};
					return;
				}
				const addressComponents = geoLocation.address;
				this.address_info.lat = geoLocation.lat;
				this.address_info.lng = geoLocation.lon;
				this.formattedAddress = geoLocation.display_name;
				this.address_info.address = this.formattedAddress;
				this.address_info.city = addressComponents.city;
				this.address_info.state = addressComponents.state;
				this.address_info.country = addressComponents.country;
				this.address_info.postal_code = addressComponents.postcode;
				if (this.address_info.lat !== null) {
					const tz = await this.timeZone(
						this.address_info.lat,
						this.address_info.lng,
					);
					if (tz) {
						this.timezone = tz.zoneName;
					}
				}
			}
		},
		async timeZone (lat, lng) {
			const object = {
				lat: lat,
				lng: lng,
			};
			const timeZone = await this.$store.dispatch(
				'googleAPI/getTimeZone',
				object,
			);
			return timeZone;
		},
		setImperialOrMetric (unit) {
			this.scaleAndTempUnit = unit;
			this.changePreferences();
		},
		setGlucoseAndCholesterolUnit (unit) {
			this.glucoseAndCholesterolUnit = unit;
			this.changePreferences();
		},
		changePreferences () {
			const body = {
				scaleAndTempUnit: this.scaleAndTempUnit,
				glucoseAndCholesterolUnit: this.glucoseAndCholesterolUnit,
			};
			this.$store.dispatch('metrics/setMetric', body);
		},
		async update () {
			const userSetttingsBody = {
				Units: this.scaleAndTempUnit,
				Concentration: this.glucoseAndCholesterolUnit,
			};
			this.snackStatus = false;
			const body = {
				id: this.userDetails.id,
				firstName: this.userDetails.firstName,
				lastName: this.userDetails.lastName,
				phone: this.userDetails.phone.split(' ').join(''),
				email: this.userDetails.email,
				address: this.address_info.address,
				zip: this.address_info.postal_code,
				city: this.address_info.city,
				state: this.address_info.state,
				country: this.address_info.country,
				addressLatitude: this.address_info.lat,
				addressLongitude: this.address_info.lng,
				userTimezone: this.timezone,
				username: this.userDetails.username,
				status: this.userDetails.status,
				gender: this.userDetails.gender,
				roleId: this.userDetails.roleId,
				birthdate: this.birthdateAsString,
				userSettingsJson: JSON.stringify(userSetttingsBody),
			};
			await this.$store
				.dispatch('users/updateUser', body)
				.then((res) => {
					location.reload();
					this.$emit('false');
					this.snackStatus = true;
					this.snackColor = 'teal darken-2';
					this.title = this.$t('profileUpdatedSuccessfully');
					this.$store.dispatch('authentication/changePrefenrences', res.data);
				})
				.catch(() => {
					this.snackStatus = true;
					this.snackColor = 'deep-orange darken-4';
					this.title = this.$t('profileCouldNotUpdate');
				});
		},
		async changePassword () {
			const body = {
				currentPassword: this.currentPassword,
				newPassword: this.confirmNewPassword,
			};
			await this.$store
				.dispatch('users/changePassword', body)
				.then((res) => {
					if (res.resFlag) {
						this.changePasswordSection = false;
						this.currentPassword = '';
						this.newPassword = '';
						this.confirmNewPassword = '';
						this.snackStatus = true;
						this.snackColor = 'teal darken-2';
						this.title = this.$t('passwordChangedSuccessfully');
					}
				})
				.catch(() => {
					this.changePasswordSection = false;
					this.snackStatus = true;
					this.snackColor = 'deep-orange darken-4';
					this.title = this.$t('passwordCouldNotBeChanged');
					this.currentPassword = '';
					this.newPassword = '';
					this.confirmNewPassword = '';
				});
		},
	},
};
</script>
